
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {
  computed, defineComponent,
} from 'vue'
import Page from '@/components/Page.vue'
import Box from '@/components/Box.vue'
import NewHubUserForm from '@/components/Forms/NewHubUserForm.vue'
import { TUser } from '@/types'
import PageTop from '@/components/PageTop.vue'

export default defineComponent({
  components: {
    Page,
    Box,
    NewHubUserForm,
    PageTop,
  },
  setup() {
    const store = useStore()

    const route = useRoute()
    const user = computed<TUser | undefined>(
      () => route.params.userID && store.getters.getUserByID(route.params.userID),
    )
    return {
      user,
      environment: store.getters.environment,
    }
  },
})

