
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  computed, onMounted, ref, defineComponent,
} from 'vue'
import InputField from '@/components/Form/InputField.vue'
import Button from '@/components/Form/Button.vue'
import Select from '@/components/Form/Select.vue'
import Toggle from '@/components/Form/Toggle.vue'
import CheckboxGroup from '@/components/Form/CheckboxGroup.vue'
import {
  IAccount, IUserBase, TUser, IUserHubData, RoleHub,
} from '@/types'
import { isHubUser } from '@/globals/javascript/utils/typeGuards'

export default defineComponent({
  components: {
    InputField,
    Button,
    Toggle,
    Select,
    CheckboxGroup,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isLoading = ref(true)
    const successMessage = ref(false)
    const failMessage = ref<string>()
    const checkboxAdmin = ref<string[]>([])

    const data = ref<{
          userID: string,
          base: Omit<IUserBase, 'firstTimeLogin' | 'numberID' | 'createdAt'>,
          hub: Omit<IUserHubData, 'isActivated'| 'activatedAt'| 'projects'>
        }>({
          userID: '',
          base: {
            id: '',
            fullName: '',
            initials: '',
            email: '',
            phoneNumber: '',
            mustUpdatePassword: true,
            isAdmin: false,
          },
          hub: {
            isActive: true,
            accountID: '',
            lastSeen: '',
            roles: [],
          },
        })

    const isSelectedUserMissingData = computed(() => {
      if (!data.value.userID) {
        return false
      }
      const selectedUser: TUser |undefined = store.getters.users.find(
        (user: TUser) => user.id === data.value.userID,
      )
      if (!selectedUser) {
        return false
      }
      // Only Pro user without account
      return !selectedUser.fullName || !selectedUser.phoneNumber || !selectedUser.initials
    })
    const existingUserOptions = computed<{key: string; value: string}[]>(
      () => {
        const options: {key: string; value: string}[] = store.getters.proUsers
          .reduce(
            (prev: {key: string, value: string}[], user: TUser) => {
              // skip if already hub user
              if (!isHubUser(user)) {
                prev.push({ key: user.id, value: user.email })
              }

              return prev
            }, [],
          )
        const optionsSorted = options.sort((a, b) => {
          if (a.value > b.value) {
            return 1
          } if (a.value < b.value) {
            return -1
          }
          return 0
        })

        return [{ key: '', value: '-' }, ...optionsSorted]
      },
    )

    const availableRoles = [
      {
        key: RoleHub.SUPER_USER,
        label: 'Super user',
      },
    ]

    function toggleAdminRole() {
      if (!data.value) {
        return
      }
      if (checkboxAdmin.value.includes('admin')) {
        checkboxAdmin.value = []
        data.value.base.isAdmin = false
        return
      }
      checkboxAdmin.value = ['admin']
      data.value.base.isAdmin = true
    }

    const accounts = computed<{key: string; value: string}[]>(() => store.getters['hubStore/accounts'].map(
      (account: IAccount) => ({ key: account.id, value: account.name }),
    ))

    onMounted(() => {
      const preSelectedUser = route.params.userID && store.getters.users.find(
        (user: TUser) => user.id === route.params.userID && !isHubUser(user),
      )

      data.value.userID = preSelectedUser?.id ?? ''
      isLoading.value = false
    })

    const onSubmit = () => {
      if (isLoading.value) {
        return
      }
      failMessage.value = undefined
      isLoading.value = true

      store.dispatch('createHubUser', {
        data:
        {
          userData: data.value?.base,
          hubData: data.value?.hub,
          userID: data.value?.userID,
        },
      })
        .then(({ data }) => {
          isLoading.value = false
          successMessage.value = true
          setTimeout(() => {
            router.push({ name: 'User', params: { userID: data.userID } })
          }, 1000)
        }).catch((error) => {
          failMessage.value = error.message
          isLoading.value = false
        })
    }

    return {
      accounts,
      existingUserOptions,
      availableRoles,
      onSubmit,
      data,
      isLoading,
      successMessage,
      failMessage,
      toggleAdminRole,
      checkboxAdmin,
      isSelectedUserMissingData,
    }
  },
})

